import { combineReducers } from "redux";

import catalogReducer from "./catalog";
import pricesReducer from "./prices";

const rootReducer = combineReducers({
	catalog: catalogReducer,
	prices: pricesReducer
});

export default rootReducer;

import Card from "../../components/Card/Card";
import axios from "axios";
import React from "react";

import Categories from "../../components/Categories/Categories";
import CatDropdown from "../../components/Categories/CatDropdown";
import AmountBar from "../../components/Card/AmountBar";

function Glicerin6({ cart, forceUpdate, prices, sets }) {
	const [loadedData, setLoadedData] = React.useState({ products: [], size: 0 });
    
  let isDesktop = window.screen.orientation.type.includes("landscape");

	React.useEffect(() => {
    // deprecates requests, if data up to date already
    if (loadedData.size) return;
		axios.get("/get_glicerin_6")
        .then(res => setLoadedData(res.data));
	});
    
  let quantity = loadedData.size, prod = loadedData.products;

	let cards = new Array();
  let body = new Array();

	let lastCategory = null;
  let lastParams = {title: "&hidden", className: "", id: "", isActive: false};
    
	prod.forEach(product => {
		if (product.category !== lastCategory) {
			if (!lastCategory) lastCategory = product.categoryId;

			let newDD = <CatDropdown
				className={lastParams.className}
				id={lastParams.id}
				title={lastParams.title}
				cards={[...cards]}
				isActive={lastParams.isActive} />
			body.push(newDD);
			cards = [];

			if (product.categoryId === "7098004f-9135-11ef-0a80-002d00131ea2") {
				let title = (
					<span>SWEETS & DESSERTS</span>
				);
				lastParams = { title: title, className: "_rose", id: "sec3", isActive: isDesktop };
			}

			// else if (product.categoryId === "4732f22d-b8c7-11ef-0a80-056200018c3d") {
			else if (product.categoryId === '533d08a4-9135-11ef-0a80-065300137b64') {
				let title = (
					<span>FRUITS & DRINKS</span>
				);
				lastParams = { title: title, className: "_orange", id: "sec1", isActive: true };
			}
			else if (product.categoryId === "7d76ed09-9135-11ef-0a80-168400131217") {
				let title = (
					<span>ICE EDITIONS</span>
				);
				lastParams = { title: title, className: "_blue", id: "sec2", isActive: isDesktop };
			}
		}

    let quantity = 0;
    if (Object.keys(cart.stored).includes(product.code))
      quantity = cart.stored[product.code].quantity;
         
	 	let new_card = (
	 		<Card
	 			className="taste__card"
	 			title={product.name}
	 			subtitle={product.description}
	 			price={product.price + prices.niko15.price + prices.gly75.price }
				sumPrice={ (product.price + prices.niko15.price) * quantity + Math.ceil(quantity/5) * prices.gly75.price }
	 			cardSrc={product.imageURLMiniature}
	 			item={product}
	 			cart={cart}
	 			forceUpdate={forceUpdate}
	 			quantity={quantity}
	 		/>
	 	);
	 	cards.push(new_card);
	 	lastCategory = product.category;
	 });
    let newDD = <CatDropdown 
                    className={lastParams.className} 
                    id={lastParams.id}
                    title={lastParams.title} 
                    cards={[...cards]} 
                    isActive={lastParams.isActive} />
    body.push(newDD);
    cards = [];

	return (
		<>
			<h1 className="title">
				Набiр 15 мл
			</h1>
			<div className="all-goods _mob">
				<span>{quantity}</span> товарів
			</div>

			<AmountBar cart={cart} />

			<div className="taste-wrapper">
			    <Categories />
				<section className="taste">
					<div className="taste__body">{body}</div>
				</section>
			</div>
		</>
	);
}

export default Glicerin6;

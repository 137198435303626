import React from "react";

function AmountBar({ cart }) {
  // aromas
  let categories_6 = [
      '7d76ed09-9135-11ef-0a80-168400131217', // ice editions
      '7098004f-9135-11ef-0a80-002d00131ea2', // sweets and desserts
      // '4732f22d-b8c7-11ef-0a80-056200018c3d', // fruits and drinks
      '533d08a4-9135-11ef-0a80-065300137b64' // also fruits and drinks
  ];

  let categories_12 = [
      'ab22539f-b5f6-11ee-0a80-05fc0027a1a3', // ice editions
      '2ce4d356-b209-11ee-0a80-032d001d7154', // sweets and desserts
      'c3f08641-b0c4-11ee-0a80-0ea30001ed85' // fruits and drinks
  ];

  let categories_18 = [
      '513ab57e-b209-11ee-0a80-032d001d7495',
      'ee1da28d-b5f6-11ee-0a80-14f000286c83',
      '4947ed06-b209-11ee-0a80-066b001dc47d'
  ];

  let categories_24 = [
      '99be6029-9607-11ef-0a80-0d210016615a', //sweets and desserts
      '8379b5f6-9606-11ef-0a80-19d600160655', //fruits and drinks
      'b9902817-9607-11ef-0a80-13280015b439' // ice editions
  ];


  let glicerine = '450502d2-2d5a-11ee-0a80-00790012aadd';
  let nicotine = '4a94da25-2d5a-11ee-0a80-09140012defa';

  let got6ml = 0, got12ml = 0, got18ml = 0, got24ml = 0, gotNicotine = 0, gotGlicerine = 0;

  Object.values(cart.stored).forEach(product => {
      if (categories_6.includes(product.categoryId)) got6ml += product.quantity;
      else if (categories_12.includes(product.categoryId)) got12ml += product.quantity;
      else if (categories_18.includes(product.categoryId)) got18ml += product.quantity;
      else if (categories_24.includes(product.categoryId)) got24ml += product.quantity;

      else if (product.categoryId == glicerine) gotGlicerine += product.quantity;
      else if (product.categoryId == nicotine) gotNicotine += product.quantity;
  });

  return (
    <>
      <p className="aroma__header">
        Вже додано:
      </p>
      <div style={{ "padding-top": "1rem", "padding-bottom": "1rem", "display": "flex" }}>
        <br />
        <div className="aroma__container" style={{ "margin-right": "1.5rem" }}>
          <p className="aroma__title">
            Аром. 6 мл <span style={{ "border-bottom": "1px solid gray" }}>{got6ml}</span> шт,
          </p>
          <p className="aroma__title">
            Аром. 12 мл <span style={{ "border-bottom": "1px solid gray" }}>{got12ml}</span> шт.
          </p>
        </div>
        <div className="aroma__container" style={{ "margin-right": "1.5rem" }}>
          <p className="aroma__title">
            Аром. 18 мл <span style={{ "border-bottom": "1px solid gray" }}>{got18ml}</span> шт,
          </p>
          <p className="aroma__title">
            Аром. 24 мл <span style={{ "border-bottom": "1px solid gray" }}>{got24ml}</span> шт.
          </p>
        </div>
        <div className="aroma__container">
          <p className="aroma__title">
            Нiкотин: &nbsp;&nbsp;<span style={{ "border-bottom": "1px solid gray" }}>{gotNicotine}</span> шт,
          </p>
          <p className="aroma__title">
            Глiцерин: <span style={{ "border-bottom": "1px solid gray" }}>{gotGlicerine}</span> шт.
          </p>
        </div>

      </div>
    </>
  );
}

export default AmountBar;

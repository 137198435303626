import React from "react";
import axios from "axios";

import ButtonCheckout from "../components/UI/Buttons/ButtonCheckout";

function Checkout({ setActivePage, cart, forceUpdate, prices, sets }) {
	let products = Object.values(cart.stored);

	// подсчитывает количество и стоимость товаров в корзине
	// сделан без стейтов, т.к. все равно должен пересчитываться на каждый рендер
	let images = new Array();
	let sumQuantity = 0, sumPrice = 0;
	let sets6ml = 0, sets24ml = 0;
	let addedNiko15 = false, addedNiko3 = false;

		// counting added nicotine first
		for (const product of products) {
			if (sets.ml6.includes(product.categoryId)) {
				sets6ml += product.quantity;
			} else if (sets.ml24.includes(product.categoryId)) {
				sets24ml += product.quantity;
			}
		}

	products.forEach(product => {
		sumQuantity += product.quantity;
    let price;

		if (sets.ml6.includes(product.categoryId) && !addedNiko15) {
			price = (product.price + prices.gly75.price) + prices.niko15.price;
			sumPrice += (product.price + prices.gly75.price)*product.quantity + Math.ceil(sets6ml/5) * prices.niko15.price*5;
			addedNiko15 = true;

		} else if (sets.ml24.includes(product.categoryId) && !addedNiko3) {
			price = (product.price + prices.gly30.price) + prices.niko3.price;
			sumPrice += (product.price + prices.gly30.price)*product.quantity + Math.ceil(sets24ml/5) * prices.niko3.price*5;
			addedNiko3 = true;

		} else {
			if (sets.ml6.includes(product.categoryId)) {
				price = (product.price + prices.gly75.price) + prices.niko15.price;
				sumPrice += (product.price + prices.gly75.price)*product.quantity;

			} else if (sets.ml24.includes(product.categoryId)) {
				price = (product.price + prices.gly30.price) + prices.niko3.price;
				sumPrice += (product.price + prices.gly30.price)*product.quantity;

			} else {
				price = product.price;
				sumPrice += price * product.quantity;
			}
		}

		let newImg = (
			<div className="checkout__images-container">
				<span className="checkout__images-count">{product.quantity}</span>
				<img
					src={product.imageURLMiniature}
					alt=""
					className="checkout__images-item"
				/>
			</div>
		);
		images.push(newImg);
	});

	let phoneRef = React.createRef(),
		nameRef = React.createRef(),
		commentRef = React.createRef();

    const onPhoneFocus = e => {
        if (!e.target.value)
            e.target.value = "+380";
    }
    const onPhoneChange = e => {
        let allowed = ['Backspace', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '(', ')', '-', '+']
        if (!allowed.includes(e.key))
            e.preventDefault(); 
    }
    
	const makeOrder = () => {
		let phone = phoneRef.current.value;
		let name = nameRef.current.value;
		let comment = commentRef.current.value;

    // тут конечно лучше более серьезную валидацию на regexp сделать, но об этом не просили
    if (!name.length || !phone.length || phone == "+380") 
      return alert("поля Назва компанії і телефон обов'язкові до заповнення");
        
		let products = Object.values(cart.stored);
		console.log('products is: ', products);

		// adding glycerine and nicotine to sets
		let sets6ml = 0;
		let sets24ml = 0;

		for (const product of products) {
			if (sets.ml6.includes(product.categoryId)) {
				sets6ml += product.quantity;
			} else if (sets.ml24.includes(product.categoryId)) {
				sets24ml += product.quantity;
			}
		}
		// console.log('sets', sets6ml, sets24ml)

		const addedNiko15 = products.find(prod => prod.code === prices.niko15.code);
		console.log('niko 15 code / added niko 15: ', addedNiko15);
		const addedNiko3 = products.find(prod => prod.code === prices.niko3.code);
		console.log('niko 15 code / added niko 15: ', addedNiko3);

		if (sets6ml) {
			const addedGlycerine = {...prices.gly75, quantity: sets6ml};
			products.push(addedGlycerine);

			const niko15Quantity = Math.ceil(sets6ml/5)*5;
			if (addedNiko15) {
				addedNiko15.quantity += niko15Quantity;
			} else {
				const addedNicotine = {...prices.niko15, quantity: niko15Quantity};
				products.push(addedNicotine);
			}
			
		}

		if (sets24ml) {
			const addedGlycerine = {...prices.gly30, quantity: sets24ml};
			products.push(addedGlycerine);

			const niko3Quantity = Math.ceil(sets24ml/5)*5;
			if (addedNiko3) {
				addedNiko3.quantity += niko3Quantity;
			} else {
				const addedNicotine = {...prices.niko3, quantity: niko3Quantity};
				products.push(addedNicotine);
			}
		}

		let data = JSON.stringify({
			products: products,
			phone: phone,
			name: name,
			comment: comment,
		});

		console.log('products: ', products);
		// return;

		let queryString = window.location.search;
		console.log(queryString);

		let urlParams = new URLSearchParams(queryString);
		let agent = urlParams.get('agent')
		console.log('agent', agent);
		
		if(agent)
			axios.post(`/order?agent=${agent}`, data, {
				headers: {
					"Content-Type": "application/json;charset=UTF-8",
				},
			});
		else axios.post("/order", data, {
			headers: {
				"Content-Type": "application/json;charset=UTF-8",
			},
		});
        
		cart.clear();
		forceUpdate();

		setActivePage("thanks");
	};

	return (
		<div className="checkout">
			<h1 className="title">Оформлення замовлення</h1>

			<div className="checkout__content">
				<div className="checkout__form">
					<label htmlFor="_company" className="checkout__label">
						Назва компанії
						<input
							ref={nameRef}
							type="text"
							id="_company"
							placeholder="Назва компанії"
							className="checkout__input _company"
							required
						/>
					</label>
					<label htmlFor="_tel" className="checkout__label">
						Телефон для зв'язку
						<input
							ref={phoneRef}
							type="tel" 
							placeholder="+380-yy-xxxxxxx"
							className="checkout__input _tel"
							onFocus = {onPhoneFocus}
							onKeyDown = {onPhoneChange}
							required
						/>
					</label>
					<label htmlFor="_tel" className="checkout__label">
						Коментар до замовлення
						<textarea
							ref={commentRef}
							name="commentar"
							id="_tel"
							className="checkout__textarea"
							placeholder="Якщо маєте певні примітки до замовлення, напишіть їх тут"
						></textarea>
					</label>
				</div>
				<div className="checkout__body">
					<div className="checkout__body-header">
						<h2 className="checkout__body-title">Ваше замовлення</h2>
						<h2 className="checkout__body-subtitle">
							<span>{sumQuantity}</span> товарів
						</h2>
					</div>
					<div className="checkout__images">{images}</div>
					<div className="checkout__price">
						<span>{sumPrice}</span> грн
					</div>
					<ButtonCheckout className="checkout__confirm" onClick={makeOrder}>
						Підтвердити замовлення
					</ButtonCheckout>
					<p className="checkout__confirm-text" style={{textAlign: 'center', paddingTop: '.5rem'}}>
						Натискаючи кнопку «Підтвердити замовлення», я підтверджую, що
						досяг(ла) 18 років
					</p>
				</div>
			</div>
		</div>
	);
}

export default Checkout;

import React from "react";

import { useDispatch } from "react-redux";
import { setPrices } from "./redux/actions/prices";

import { Header } from "./components";
import Cart from "./pages/Cart";
import Home from "./pages/Home";
import Checkout from "./pages/Checkout";

import CartComponent from './components/Cart/Cart';
import axios from "axios";


function App({ cart })
{
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    
    const [activePage, setActivePage] = React.useState("catalog");

		const [prices, setPrices] = React.useState({ notUpdated: true });

		React.useEffect(() => {
			if (prices.notUpdated) {
				axios.get('/get-prices')
				.then(r => {
					setPrices({ ...r.data, notUpdated: false });
				});
			}
		}, [])
    
    const catalog = [
        "Набiр 15 мл",
        "Премiкс 12 мл",
				"Премiкс 18 мл",
				"Набiр 60 мл",
        "Глiцерин",
        "Нiкобустери",
        "Iнше",
    ];

		// наборы
		const sets = {
			// 6
			ml6: [
				'7d76ed09-9135-11ef-0a80-168400131217', // ice editions
        '7098004f-9135-11ef-0a80-002d00131ea2', // sweets and desserts
        // '4732f22d-b8c7-11ef-0a80-056200018c3d', // fruits and drinks
        '533d08a4-9135-11ef-0a80-065300137b64' // also fruits and drinks?...
			],
			// 24
			ml24: [
				'99be6029-9607-11ef-0a80-0d210016615a', //sweets and desserts
				'8379b5f6-9606-11ef-0a80-19d600160655', //fruits and drinks
				'b9902817-9607-11ef-0a80-13280015b439', // ice editions
			],
		}
        
    let cartComponent = <CartComponent activePage={activePage} setActivePage={setActivePage} cart={cart} prices={prices} sets={sets} />

    return (
		<>
			<Header
				catalog={catalog}
				activePage={activePage}
				setActivePage={setActivePage}
				cart = {cartComponent}
			/>
			<Home
				catalogItems={catalog}
				activePage={activePage}
				setActivePage={setActivePage}
				cart = {cart}
				forceUpdate = {forceUpdate}
				prices={prices}
				sets={sets}
			/>
		</>
    );    
	
}

export default App;

import Card from "../../components/Card/NikoCard";
import axios from 'axios';
import React from "react";

import AmountBar from "../../components/Card/AmountBar";

function Nikobuster({ cart, forceUpdate }) {
    const [loadedData, setLoadedData] = React.useState({products: [], size: 0});
    
    React.useEffect(() => {
        // deprecates requests, if data up to date already
        if (loadedData.size) return;
        axios.get('/get_nicobooster')
        .then(res => setLoadedData(res.data));
    });
    
    // cards
    let quantity = loadedData.size, prod = loadedData.products;
    
    let cards = new Array();
    prod.forEach(product => {
        
        let quantity = 0;
        if (Object.keys(cart.stored).includes(product.code))
            quantity = cart.stored[product.code].quantity;
        
        let new_card = <Card 
            className = 'taste__card'
            title = {product.name}
            subtitle = {product.description}
            price = {product.price}
            cardSrc = {product.imageURLMiniature}
            item = {product}
            cart = {cart}
            forceUpdate = {forceUpdate}
            quantity={quantity}
        />
        cards.push(new_card);
    });
    
	return (
		<>
			<h1 className="title">Нiкобустери</h1>
			<div className="all-goods _mob">
				<span>{quantity}</span> товарів
			</div>
        
            <br/>
        
            <div style = {{"color": "darkred"}}>
                Кількість нікобустерів повинна бути кратною 5.  <br/>
                На одній пластині п'ять нікобустерів. <br/>
                Якщо буде обрана не кратна п’яти кількість, <br/>
                менеджер округлить її до кратної 5.
            </div>
        
            <br />
            
			<AmountBar cart={cart}/>

			<section className="taste">
				<div className="taste__content">
					<div className="taste__body">
						
                        { cards }
                        
					</div>
				</div>
			</section>
		</>
	);
}

export default Nikobuster;

const defaultState = {
	gly75: 0,
	gly30: 0,
	niko15: 0,
	niko3: 0,
};

const pricesReducer = (state = defaultState, action) => {
	switch (action.type) {
		case "SET_PRICE":
			return { ...state, prices: action.prices };
		default:
			return state;
	}
};

export default pricesReducer;
